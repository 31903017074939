import { combineReducers } from 'redux'

import authReducer from './authSlice'
import tariffSlice from './tariffSlice'
import loaderSlice from './loaderSlice'
import tariffDetailsrSlice from './tariffDetails'

const rootReducer = combineReducers({
  auth: authReducer,
  tariff: tariffSlice,
  loader: loaderSlice,
  tariffDetail: tariffDetailsrSlice
})

export default rootReducer