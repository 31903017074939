import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false
}

const loaderSlice = createSlice({
    name:"loader",
    initialState,
    reducers:{
        startLoading: (state, action) =>{
            state.isLoading = true
        },
        stopLoading: (state, action) =>{
            state.isLoading = false
        }
    }
})

export const { startLoading, stopLoading } = loaderSlice.actions
export default loaderSlice.reducer