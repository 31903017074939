import React, { useState, useEffect, useRef } from "react";
import Datatable from "../components/Datatable";
import { useDispatch, useSelector } from "react-redux";
import { getAllTariffAction } from "../redux/action/tariffAction";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { uploadFile } from "../redux/action/tariffAction";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import { updateUploadFileStatue, addBase64TraiffFile } from "../redux/slices/tariffSlice";

import "./pages.scss";

const Home = (props) => {
  const toast = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [tariffList, setTariffList] = useState([]);
  const [file, setFile] = useState(null);
  const [ihxHospitalId, setihxHospitalId] = useState("");
  const [hospitalName, sethospitalName] = useState("");
  const [payorHospitalId, setpayorHospitalId] = useState("");
  const [payorName, setpayorName] = useState("");

  const tariffData = useSelector((state) => state.tariff);

  useEffect(() => {
    props.setCurrentPage("home");
    const fetchTariff = async () => {
      await dispatch(getAllTariffAction());
    };
    fetchTariff();
  }, []);

  useEffect(() => {
    console.log(tariffData.tariffData.tariffData);
  }, [tariffData.tariffData.tariffData]);

  useEffect(() => {
    if (tariffData.fileUpload.status) {
      navigate("/tariff-studio/" + tariffData.fileUpload.data.tariffId);
      dispatch(updateUploadFileStatue());
    }
  }, [tariffData.fileUpload.status]);

  const onChange = (e) => {
    let files = e.target.files;
    setFile(files[0]);
    console.log(files,'filesfilesfilesfilesfiles')
  };

  const convertToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const getBase64 = async () =>{
    let base64 = await convertToBase64(file)
    await dispatch(addBase64TraiffFile(base64))
  }

  
  useEffect(() =>{
    if (file){
      getBase64()
    }
  },[file])

  const onUpload = async (data) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("ihxHospitalId", ihxHospitalId);
      formData.append("hospitalName", hospitalName);
      formData.append("payorHospitalId", payorHospitalId);
      formData.append("payorName", payorName);

      await dispatch(uploadFile(formData));
      props.setUplodFileClick(false);
      showSuccess("File Successfully Uploaded");
    } catch (err) {}
  };

  const showSuccess = (mesg) => {
    toast.current.show({ severity: "success", detail: mesg, life: 3000 });
  };

  const deleteFile = () => {
    setFile(null);
  };

  const renderFooter = (name) => {
    return (
      <div>
        <Button
          label="Cancel"
          icon="pi pi-times"
          onClick={() => props.setUplodFileClick(false)}
          className="p-button-text cancel_btn"
        />
        <Button
          label="Upload"
          icon="pi pi-check"
          onClick={onUpload}
          className="upload_file"
          disabled={file ? false : true}
        />
      </div>
    );
  };

  return (
    <div>
      <Datatable
        tariffList={
          tariffData.tariffData ? tariffData.tariffData.tariffData : []
        }
        setCurrentPage={props.setCurrentPage}
      />

      <Dialog
        header="Uplaod File"
        visible={props.uploadFileClick}
        style={{ width: "40vw" }}
        footer={renderFooter()}
        onHide={() => props.setUplodFileClick(false)}
      >
        <div>
          <div className="grid">
            <div className="col-6">
              <InputText
                className="popup_input_box"
                value={ihxHospitalId}
                onChange={(e) => setihxHospitalId(e.target.value)}
                placeholder="Ihx Hospital ID"
              />
            </div>
            <div className="col-6">
              <InputText
                className="popup_input_box"
                value={hospitalName}
                onChange={(e) => sethospitalName(e.target.value)}
                placeholder="Hospital Name"
              />
            </div>
          </div>
        </div>

        <div>
          <div className="grid">
            <div className="col-6">
              <InputText
                className="popup_input_box"
                value={payorHospitalId}
                onChange={(e) => setpayorHospitalId(e.target.value)}
                placeholder="Payor Hospital ID"
              />
            </div>
            <div className="col-6">
              <InputText
                className="popup_input_box"
                value={payorName}
                onChange={(e) => setpayorName(e.target.value)}
                placeholder="Payor Name"
              />
            </div>
          </div>
        </div>

        <div className="file_upload_popup">
          <label className="custom-file-upload">
            <input type="file" onChange={onChange} />
            <i className="pi pi-upload"> Select File</i>
            {/* <i className="fa fa-cloud-upload" />  */}
          </label>

          {file && (
            <div className="file-preview">
              {file.name}
              <i
                className="pi pi-trash delete_selected_image"
                onClick={deleteFile}
              ></i>
            </div>
          )}
        </div>
      </Dialog>

      <Toast ref={toast} position="top-center" />
    </div>
  );
};

export default Home;
