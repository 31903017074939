import React, { useState, useEffect, useRef } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Header from "./components/Header";
import TariffStudio from "./pages/TariffStudio";

import { isLoggedIn } from "./utils";
import PageNotFound from "./pages/PageNotFound";

const ProtectedRoute = ({ children }) => {
  const auth = isLoggedIn();
  if (!isLoggedIn()) {
    return <Navigate to="/" replace />;
  }
  return children;
};

const Routers = () => {
  const [uploadFileClick, setUplodFileClick] = useState(false);
  const [currentPage, setCurrentPage] = useState('home')

  const childRef = useRef();

  const downloadClick = () =>{
    // alert()
    childRef.current.downloadClickHnadle()
  }

  return (
    <div>
      <Header setUplodFileClick={setUplodFileClick} currentPage={currentPage} downloadClick={() =>childRef.current.downloadClickHnadle()} saveAsDraftClick={() => childRef.current.saveAsDraftClickHnadle()}
      finishClick={() => childRef.current.finishClickHnadle()}
      />
      <Routes>
        <Route
          path="/home"
          element={
            <ProtectedRoute>
              <Home
                uploadFileClick={uploadFileClick}
                setUplodFileClick={setUplodFileClick}
                setCurrentPage={setCurrentPage}
              />
            </ProtectedRoute>
          }
          exact
        />
        <Route path="/tariff-studio/:id" element={<TariffStudio ref={childRef} setCurrentPage={setCurrentPage}/>} exact />

        <Route path="*" element={<Navigate to="/not-found" replace />}
 />
      </Routes>
    </div>
  );
};

export default Routers;
