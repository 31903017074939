import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "./component.scss";
import { convertText } from "../utils";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { changeDatatableHeaderText } from "../utils";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { checkTariffFinished } from "../utils";


const TariffQcDatatable = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    async AddNewRow(val) {
      let data = structuredClone(pageWiseData);
      for (let i = 1; i <= val; i++) {
        let obj = structuredClone(data[0]);
        let keys = Object.keys(obj);
        keys.forEach((item) => {
          if (item !== "pageNo" && item !== "tariffId") {
            obj[item] = "";
          }
        });
        obj["id"] = `add_new_row_${i}`;
        obj["newRow"] = true;
        data.unshift(obj);
      }
      setPageWiseData(data);
    },
  }));

  // state data
  const [selectedCustomers, setSelectedCustomers] = useState(null);
  const [column, setColumn] = useState([]);
  const [pageWiseData, setPageWiseData] = useState([]);

  // redux store data
  const tariffSTatus = useSelector((state) => state.tariff.tariffDetails);
  const tariffrowAddedIds = useSelector((state) => state.tariff.newTariffRowId);
  

  useEffect(() => {
    if (tariffrowAddedIds.length > 0) {
      for (let i = 0; i <= tariffrowAddedIds.length; i++) {
        pageWiseData[i]["_id"] = tariffrowAddedIds[i];
      }
    }
  }, [tariffrowAddedIds]);

  useEffect(() => {
    if (props.data.length > 0 && props.pageNo) {
      let pageData = props.data.filter(function (el) {
        return el.pageNo === props.pageNo;
      });
      let data = structuredClone(pageData);
      setPageWiseData(data);
    }

    if (props.data.length > 0) {
      let col = [...column];
      Object.keys(props.data[0]).map((item, index) => {
        if (
          item !== "tariffId" &&
          item !== "pageNo" &&
          item !== "_id" &&
          item !== "updatedAt" &&
          item !== "__v" &&
          item !== "createdAt"
        ) {
          let colExists = col.includes(item);
          if (colExists === false) {
            col.push(item);
          }
        }
      });
      setColumn(col);
    }
    if (props.data.length > 0 && props.pageNo) {
      let data = [];
      props.data.forEach((item) => {
        if (item.pageNo === props.pageNo) {
          data.push({
            pageNo: item.pageNo,
            rowData: item,
            updateType: "",
            rowId: item._id,
          });
        }
      });
      props.setMOdifiedData(data);
    }
  }, [props.data, props.pageNo]);

  useEffect(() => {
    updateDeleteRow(false, "add");
  }, [pageWiseData]);

  const updateDeleteRow = async (data, type, updateNewAddedRow = false) => {
    let a = structuredClone(props.rowUpdateData);

    if (type === "update") {
      if (updateNewAddedRow) {
        let existing_row = a.filter((el) => el.rowData.id === data.id);
        existing_row.rowData = data;
      } else {
        let existing_row = a.filter((el) => el.rowId === data._id);
        if (existing_row.length === 0) {
          let b = {
            pageNo: data["pageNo"],
            rowData: data,
            rowId: data["_id"],
            updateType: type,
          };
          a.push(b);
        } else {
          a.forEach((el) => {
            if (el.rowId === data._id) {
              el.rowData = data;
            }
          });
        }
        props.setRowUpdateData(a);
      }
    } else if (type === "add" && data === false) {
      let c = pageWiseData.filter((el) => el.newRow === true);

      if (c.length > 0) {
        c.forEach((el) => {
          delete el["_id"];
          let b = {
            pageNo: props.pageNo,
            rowData: el,
            rowId: "",
            updateType: type,
          };
          a.push(b);
        });
        await props.setRowUpdateData(a);
      }
    } else if (type === "delete") {
      let b = {
        rowId: data["_id"],
        updateType: type,
      };
      a.push(b);
      props.setRowUpdateData(a);

      const index = pageWiseData.findIndex((el) => el._id === data._id);
      pageWiseData.splice(index, 1);
    }
  };

  const cellEditor = (options) => {
    return textEditor(options);
  };

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  const onCellEditComplete = async (e) => {
    let { rowData, newValue, field, originalEvent: event } = e;
    rowData[field] = newValue;

    if (rowData.newRow === true) {
      updateDeleteRow(rowData, "update", true);
    } else {
      updateDeleteRow(rowData, "update");
    }
  };

  const rowClass = (data) => {
    return {
      new_row: data.newRow === true,
    };
  };

  const deleteRow = (data) => {
    updateDeleteRow(data, "delete");
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div className="action_button">
          {/* <Button
            icon="pi pi-plus-circle"
            className="p-button-rounded p-button-success w-auto h-auto mr-2 add_new_btn action_btn"
            
          />
          <Button
            icon="pi pi-copy"
            className="p-button-rounded p-0 w-auto h-auto mr-2 copy_btn action_btn"
            
          /> */}
          <Button
            icon="pi pi-trash"
            className="p-button-rounded w-auto h-auto p-0 cancel_btn action_btn"
            onClick={() => deleteRow(rowData)}
            disabled={checkTariffFinished(tariffSTatus) ? true : false}
          />
        </div>
      </React.Fragment>
    );
  };

  return (
    <div>
      <div className="qc_table">
        <DataTable
          value={pageWiseData}
          className="tarrif_home_table datatable_qc"
          selection={selectedCustomers}
          onSelectionChange={(e) => setSelectedCustomers(e.value)}
          editMode="cell"
          rowClassName={rowClass}
          scrollable
        >
          {column.map((item, index) => {
            if (checkTariffFinished(tariffSTatus)) {
              return (
                <Column
                  field={item}
                  header={changeDatatableHeaderText(item)}
                  key={index}
                  style={{ minWidth: "10rem" }}
                  className={item}
                ></Column>
              );
            } else {
              return (
                <Column
                  editor={(options) => cellEditor(options)}
                  onCellEditComplete={onCellEditComplete}
                  field={item}
                  header={changeDatatableHeaderText(item)}
                  key={index}
                  style={{ minWidth: "8em", width: "100%" }}
                  className={item}
                ></Column>
              );
            }
          })}

          <Column
            header="Action"
            body={actionBodyTemplate}
            exportable={false}
            style={{ minWidth: "8rem" }}
            frozen
            alignFrozen="right"
            className="action_data"
          ></Column>
        </DataTable>
      </div>
    </div>
  );
});

export default TariffQcDatatable;
