import React from "react";
import "./component.scss";

const Loader = () => {
  return (
    <div className="loading-screen">
      <div className="loadingio-spinner-spinner-nlbm9ig4fhm">
        <div className="innerloading">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
