import { BrowserRouter, Route, Routes } from "react-router-dom";
import Routers from "./Routers";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import Login from "./pages/Login";
import "./App.css";
import Loader from "./components/Loader";
import { useSelector } from "react-redux";

import PageNotFound from "./pages/PageNotFound";

import axios from "axios";
import { get_config } from "./utils";

import { baseApiUrl } from "./config/config";

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const originalRequest = error.config;

    let refreshToken = localStorage.getItem("refresh_token");

    if (error.response.status === 401 && refreshToken) {
      if ("data" in originalRequest && originalRequest.data !== undefined) {
        let token = JSON.parse(originalRequest.data);
        if ("refreshToken" in token) {
          localStorage.clear();
          window.location.replace("/");
        }
      }
      const data = {
        refreshToken: refreshToken,
      };
      return axios
        .post(`${baseApiUrl}v1/auth/refresh-tokens`, data, get_config())
        .then((res) => {
          localStorage.setItem("access_token", res.data.access.token);
          localStorage.setItem("refresh_token", res.data.refresh.token);
          error.config.headers.Authorization =
            "Bearer " + res.data.access.token;
          console.log("Access token refreshed!");
          return axios(originalRequest);
        });
    }
    return Promise.reject(error);
  }
);

function App() {
  const loader = useSelector((state) => state.loader);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} exact />
          <Route path="*" element={<Routers />} exact />
          <Route path="/not-found" element={<PageNotFound />} exact />
        </Routes>
      </BrowserRouter>

      {loader.isLoading && <Loader />}
    </div>
  );
}

export default App;
