import React, { useState, useEffect } from "react";
import "./component.scss";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { useDispatch, useSelector } from "react-redux";
import { addNewTariffColumnAction } from "../redux/action/tariffAction";
import { changeTariffAddColumStatus } from "../redux/slices/tariffSlice";

const TableAction = (props) => {
  const [addRowNo, setAddRowNo] = useState(1);
  const [addColPopup, setAddColPopup] = useState(false);
  const [columnName, setColumnName] = useState({});

  const [newColumn, setNewColumn] = useState([]);

  const tariffSTatus = useSelector((state) => state.tariff.tariffDetails);
  const tariffTableData = useSelector((state) => state.tariff.tariffTableData);
  const addNewColumnStatus = useSelector(
    (state) => state.tariff.addNewColumnStatus
  );

  const dispatch = useDispatch();

  const onCityChange = (e) => {
    setColumnName(e.value);
  };

  const addNewColumnHandle = () => {
    dispatch(addNewTariffColumnAction(columnName, props.tariffId));
  };

  useEffect(() => {
    if (addNewColumnStatus) {
      setAddColPopup(false);
      dispatch(changeTariffAddColumStatus());
    }
  }, [addNewColumnStatus]);

  useEffect(() => {
    let arr = [];
    props.masterColumn.map((item) => {
      if (tariffTableData.length > 0) {
        let keys = Object.keys(tariffTableData[0]);
        if (item.tariffColumnName === "hospital_room_type") {
          item.columnValues.map((el) => {
            let exists = keys.includes(el.tariffColumnValueName);
            if (exists === false) {
              let obj = {
                name: el.title,
                code: el.tariffColumnValueName,
              };
              arr.push(obj);
            }
          });
        } else {
          let exists = keys.includes(item.tariffColumnName);
          if (exists === false) {
            let obj = {
              name: item.title,
              code: item.tariffColumnName,
            };
            arr.push(obj);
          }
        }
      }
    });
    setNewColumn(arr);
  }, [props.masterColumn]);

  const renderHeader = (name) => {
    return <div className="popup_header">Add New Column </div>;
  };

  const renderFooter = (name) => {
    return (
      <div className="btn_div">
        <Button
          className="cancel_bth"
          label="Cancel"
          onClick={() => setAddColPopup(false)}
        />

        <Button
          className="replace_btn"
          label="Add"
          onClick={addNewColumnHandle}
        />
      </div>
    );
  };

  return (
    <div>
      <div className="action_section">
        <div>
          <div>
            <Button
              className="save_as_draft_btn add_new_column"
              label="New Column"
              aria-label="New Column"
              onClick={() => setAddColPopup(true)}
              disabled={tariffSTatus.tariffStatus && tariffSTatus.tariffStatus.currentStatus === "verified" ? true : false}
            />
          </div>
        </div>

        <div className="row_col_input">
          <div className="grid ">
            <div className="col-3 row_input_col">
              <span className="row_input_text">Add Row:</span>
              <InputNumber
                className="row_input"
                placeholder="Column"
                value={addRowNo}
                onValueChange={(e) => setAddRowNo(e.target.value)}
                onKeyPress={(e) =>
                  e.key === "Enter" && props.addColumnClickHandle(addRowNo)
                }
                disabled={tariffSTatus.tariffStatus && tariffSTatus.tariffStatus.currentStatus === "verified" ? true : false}
              />
              <i
                className="pi pi-plus-circle icon"
                onClick={() => props.addColumnClickHandle(addRowNo)}
              ></i>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        header={renderHeader}
        footer={renderFooter}
        visible={addColPopup}
        style={{ width: "35vw" }}
        onHide={() => setAddColPopup(false)}
        className="add_column_popup"
      >
        <div className="dwopdown_section">
          <h5>Select Column :</h5>
          <Dropdown
            value={columnName}
            options={newColumn}
            onChange={(e) => onCityChange(e)}
            optionLabel="name"
            placeholder="Select a New Column"
          />
        </div>
      </Dialog>
    </div>
  );
};

export default TableAction;
