import React, { useRef, useState, useEffect } from "react";
import { Button } from "primereact/button";
import logo from "../assets/ihx-logo.jpg";
import "./component.scss";
import { Menu } from "primereact/menu";
import { useNavigate } from "react-router-dom";
import DownloadImg from "../assets/download.png";
import InfoIcon from "../assets/info.svg";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../redux/action/auth";
import { changeDatatableHeaderText } from "../utils";

const Header = (props) => {
  const menu = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [activePage, setActivePage] = useState(props.currentPage);

  const tariffStatus = useSelector((state) => state.tariffDetail);
  const tariffSTatus = useSelector((state) => state.tariff.tariffDetails);

  const logoutHandle = async () => {
    await dispatch(logoutAction(localStorage.getItem("refresh_token")));
    navigate("/");
  };

  useEffect(() => {
    setActivePage(props.currentPage);
  }, [props.currentPage]);

  const items = [
    {
      label: "Home",
      icon: "pi pi-home",
      command: (e) => {
        setActivePage("home");
        navigate("/");
      },
    },
    // {
    //   label: "Tariff Studio",
    //   icon: "pi pi-home",
    //   command: (e) => {
    //     setActivePage("tariff-studio");
    //     navigate("/tariff-studio");
    //   },
    // },
  ];

  return (
    <div>
      {/* Header upper section started  */}
      <div className="header">
        <div className="d-flex align-items-center">
          <a href="#" className="nav_logo_link">
            <img className="logo" src={logo} />
          </a>
          {/* <h1 className="header_text mb-0">
            Medi Assist Insurance TPA Private Limited
          </h1> */}
        </div>
        <div
          className="d-flex align-items-center"
          id="home_icon"
          v-if="$store.state.token"
        >
          <div className="menu-item">
            <a
              href="#"
              role="button"
              className="drop_link"
              data-bs-toggle="dropdown"
              id="user_dropdown"
              aria-expanded="false"
            >
              <img className="d-inline-block me-2" />
            </a>
            <span className="logout_btn" onClick={logoutHandle}>
              <i className="pi pi-sign-out mr-2"></i>Logout
            </span>
          </div>
        </div>
      </div>
      {/* Header upper section end */}

      {/* Header lower section started */}
      <div className="mainsection d-md-flex">
        <div className="header-lower">
          <Button
            type="button"
            className="menu_btn"
            onClick={(event) => menu.current.toggle(event)}
            aria-controls="popup_menu"
            aria-haspopup
            label={changeDatatableHeaderText(activePage)}
            icon="pi pi-angle-right icon_cstm"
            iconPos="right"
          />
          <Menu
            className="sidenav_menu"
            model={items}
            popup
            ref={menu}
            id="popup_menu"
          />
          {activePage === "home" ? (
            <div className="header_info">
              <label className="tariff_label">Tariff Listing</label>
              {/* <button className="open_tariff">
                Open Tariffs<b>164</b>
              </button> */}
            </div>
          ) : (
            <div className="header_info">
              <button className="provider_code open_tariff">
                Provider Code
                <strong>Add Code</strong>
                <img src={InfoIcon} alt="info_icon" />
              </button>
              {/* <Calendar value={startDate} onChange={(e) => setStartDate(e.value)}
              icon='pi pi-calendar'
              iconPos='right'
              showIcon={true}
              className="start_date_cale"
              placeholder="Start Date"
              ></Calendar>
              <Calendar value={startDate} onChange={(e) => setStartDate(e.value)}
              icon='pi pi-calendar'
              iconPos='right'
              showIcon={true}
              className="start_date_cale"
              placeholder="End Date"
              ></Calendar> */}
            </div>
          )}
        </div>
        <div className="header_button">
          {activePage === "home" ? (
            <Button
              label="Upload File"
              className="p-button-primary file_upload_btn"
              onClick={() => props.setUplodFileClick(true)}
            />
          ) : (
            <div>
              <Button
                className="download_btn"
                onClick={() => props.downloadClick()}
              >
                <img
                  src={DownloadImg}
                  className="download_image"
                  alt="download_image"
                />
                Download
              </Button>
              <Button
                label="Save as Draft"
                className="p-button-primary save_as_draft_btn"
                onClick={() => props.saveAsDraftClick()}
                disabled={tariffSTatus.tariffStatus && tariffSTatus.tariffStatus.currentStatus === "verified" ? true : false}
              />
              <Button
                label="Finish"
                className="p-button-primary finish_btn"
                onClick={() => props.finishClick()}
                disabled={tariffSTatus.tariffStatus && tariffSTatus.tariffStatus.currentStatus === "verified" ? true : false}
              />
            </div>
          )}
        </div>
      </div>
      {/* Header lower section started */}
    </div>
  );
};

export default Header;
