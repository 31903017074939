import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    tariffData:{},
    fileUpload:{
        data:{},
        status: false,
        mesg: '',
        isUpdate: false,
        isUpload: false
    },
    tariffTableData : [],
    tariffDetails:{},
    tariffFile: null,
    newTariffRowId:[],
    columnMaster:[],
    addNewColumnStatus: false
}


const tariffSlice = createSlice({
    name:"auth",
    initialState,
    reducers:{
        getTariffList: (state, action) =>{
            state.tariffData = action.payload
            console.log(state.tariffData)
        },
        uplaodFile: (state, action) =>{
            state.fileUpload.data = action.payload
            state.fileUpload.status = true
            state.fileUpload.isUpload = true
            state.columnMaster = action.payload.columnMaster
        },
        errorFileUpload: (state, action) =>{
            state.fileUpload.status = false
        },
        getTariffDeatils: (state, action) => {
            state.tariffDetails = action.payload
            state.tariffTableData = action.payload.extractedData
            state.tariffFile = 'data:application/pdf;base64,' + action.payload.fileData
            state.columnMaster = action.payload.columnMasterData
        },
        errorTariffDetails: (state, action) => {

        },
        updateUploadFileStatue:(state, action) =>{
            state.fileUpload.status = false
        },
        updateColumnDetails: (state, action) =>{ 
            state.tariffTableData = action.payload.extractedData
        },
        updateTariffTableData: (state, action) =>{
            let ids = []
            action.payload.status.forEach(el =>{
                if(el.rowId != undefined){
                    ids.push(el.rowId)
                }
                state.newTariffRowId = ids
            })
        },
        clearTariffStateValue: (state, action) =>{
            state.tariffTableData = []
        },
        tariffFilter: (state, action) =>{
            console.log(action.payload, 'filter action')
            state.tariffData = action.payload
            console.log(action.payload,'payload')
        },
        addNewTariffColumn: (state, action) =>{
            let oldData = JSON.parse(JSON.stringify(state.tariffTableData));
            let newData = []
            oldData.map(item =>{
                item[action.payload.code] = ''
                newData.push(item)
            })
            state.tariffTableData = newData
            state.addNewColumnStatus = true
        },
        changeTariffAddColumStatus: (state, action) =>{
            state.addNewColumnStatus = false
        },
        tariffStatusUpdate: (state, action) =>{
            state.tariffDetails.tariffStatus.currentStatus = action.payload
        },
        removeOldTariffData: (state, action) =>{
            state.tariffTableData = []
        },
        addBase64TraiffFile: (state, action) =>{
            state.tariffFile = action.payload
        }
    }
})


export const { getTariffList, uplaodFile, errorFileUpload, getTariffDeatils, errorTariffDetails, updateUploadFileStatue , updateColumnDetails, updateTariffTableData, clearTariffStateValue, tariffFilter, addNewTariffColumn, changeTariffAddColumStatus, tariffStatusUpdate, removeOldTariffData, addBase64TraiffFile} = tariffSlice.actions
export default tariffSlice.reducer