import React, { useState, useEffect, useRef } from "react";
import LoginForm from "../components/LoginForm";

import { useDispatch, useSelector } from "react-redux";
import { loginAction } from "../redux/action/auth";
import { useNavigate } from "react-router-dom";
import { isLoggedIn } from "../utils";
import { Toast } from "primereact/toast";

const Login = () => {
  const toast = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const authData = useSelector((state) => state.auth);

  useEffect(() => {
    if (isLoggedIn()) {
      navigate("/home");
    }
  }, []);

  useEffect(() =>{
    if(authData.isLogin === false && authData.mesg !== null){
      showError(authData.mesg)
    }
  },[authData])

  const showError = (mesg) => {
    toast.current.show({ severity: "error", detail: mesg, life: 3000 });
  };

  const handleLogin = async () => {
    try {
      await dispatch(loginAction(email, password));
      navigate("/home");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <LoginForm
        clickHandle={handleLogin}
        setEmail={setEmail}
        email={email}
        setPassword={setPassword}
        password={password}
      />
      <Toast ref={toast} position="top-center" />
    </div>
  );
};

export default Login;
