import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
  useRef,
} from "react";
import ReportGeneratedPopup from "../components/ReportGeneratedPopup";
import TableAction from "../components/TableAction";
import TariffQcDatatable from "../components/TariffQcDatatable";
import { useDispatch, useSelector } from "react-redux";
import {
  tariffDetailsAction,
  updateTariffTableAction,
  updateTariffStatus,
  downloadTariffData,
} from "../redux/action/tariffAction";
import { useParams } from "react-router-dom";
import FabricImages from "../components/FabricImages";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import "./pages.scss";

import { removeOldTariffData } from "../redux/slices/tariffSlice";

const TariffStudio = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    downloadClickHnadle() {
      dispatch(downloadTariffData(id));
    },
    saveAsDraftClickHnadle() {
      dispatch(updateTariffTableAction(rowUpdateData, id));
    },
    finishClickHnadle() {
      setFinishPopup(true);
    },
  }));

  const dispatch = useDispatch();
  const { id } = useParams();
  const childRef = useRef();

  const [pageNo, setPageNo] = useState(1);
  const [modefiedData, setMOdifiedData] = useState([]);
  const [finishPopup, setFinishPopup] = useState(false);
  const [rowUpdateData, setRowUpdateData] = useState([]);
  const [newRowNo, setNewRowNo] = useState(0);

  const tariffData = useSelector((state) => state.tariff.tariffDetails);
  const tariffCloumnData = useSelector((state) => state.tariff.fileUpload.data);
  const tariffTableData = useSelector((state) => state.tariff.tariffTableData);
  const tariffUpload = useSelector((state) => state.tariff.fileUpload.isUpload);
  const masterColumn = useSelector((state) => state.tariff.columnMaster);

  useEffect(() => {
    props.setCurrentPage("tariff-studio");
    const fetchTariffDetails = async () => {
      dispatch(tariffDetailsAction(id));
    };
    if (!tariffUpload) {
      fetchTariffDetails();
    }
    return () => { 
      dispatch(removeOldTariffData())
    };
  }, []);
  
  
  const changePageNoHandle = (val) => {
    if (val === "next") {
      let page_no = pageNo;
      setPageNo(page_no + 1);
    } else {
      if (pageNo !== 1) {
        let page_no = pageNo;
        setPageNo(page_no - 1);
      }
    }
  };

  const finishHandle = async () => {
    try {
      await dispatch(updateTariffStatus(id, "VERIFIED"));
      setFinishPopup(false);
    } catch (err) {
      setFinishPopup(false);
    }
  };

  const addColumnClickHandle = (value) => {
    childRef.current.AddNewRow(value);
  };

  const renderFooter = (name) => {
    return (
      <div className="btn_div">
        <Button
          className="cancel_bth"
          label="Cancel"
          onClick={() => setFinishPopup(false)}
        />

        <Button
          className="replace_btn"
          label="Yes"
          onClick={() => finishHandle()}
        />
      </div>
    );
  };

  const Header = () => {
    return <div className="popup_header"> Confirmation... </div>;
  };

  return (
    <div>
      {tariffUpload && (
        <ReportGeneratedPopup
          columnData={
            tariffCloumnData.columnExtracted
              ? tariffCloumnData.columnExtracted
              : []
          }
          tariffID={id}
        />
      )}

      <div className="grid traiff_main">
        <div className="col-5 traiff_col image_section">
          <FabricImages
            changePageNoHandle={changePageNoHandle}
            pageNo={pageNo}
          />
        </div>
        <div className="col-7 traiff_col table_section">
          <TableAction
            addColumnClickHandle={addColumnClickHandle}
            masterColumn={masterColumn}
            tariffId={id}
          />
          <TariffQcDatatable
            columnData={
              tariffData.columnData
                ? tariffData.columnData.tariffColumnValueName
                : []
            }
            data={tariffTableData ? tariffTableData : []}
            pageNo={pageNo}
            setMOdifiedData={setMOdifiedData}
            modefiedData={modefiedData}
            ref={childRef}
            rowUpdateData={rowUpdateData}
            setRowUpdateData={setRowUpdateData}
          />
        </div>
      </div>

      {/* finish confirmation popup  */}
      <div>
        <Dialog
          header={Header}
          visible={finishPopup}
          footer={renderFooter("displayBasic")}
          onHide={() => setFinishPopup(false)}
          style={{ width: "25vw" }}
          className="confirmation_popup"
        >
          <div className="popup_header">
            {" "}
            Do you want to finish this tariff ?{" "}
          </div>
        </Dialog>
      </div>
    </div>
  );
});

export default TariffStudio;
