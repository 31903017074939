import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import "./component.scss";
import Spinner from "../assets/track.png";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { updateTariffColumnData } from "../redux/action/tariffAction";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "primereact/toast";

const ReportGeneratedPopup = (props) => {
  const toast = useRef(null);
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(true);
  const [payorHospitalId, setpayorHospitalId] = useState("");
  const [payorName, setpayorName] = useState("");
  const [ihxHospitalId, setihxHospitalId] = useState("");
  const [hospitalName, setHospitalName] = useState("");
  const [steps, setSteps] = useState({});
  const [columnData, setColumnData] = useState([]);
  const [dropdownOptions, setdropdownOptions] = useState([]);
  const [roomTypedropdownOptions, setRoomTypedropdownOptions] = useState([]);
  const [childRoom, setChildRoom] = useState({});
  const [updateColumnNo, setUpdateColumnNo] = useState(0);

  const colData = useSelector((state) => state.tariff.columnMaster);

  const showWarn = () => {
    toast.current.show({
      severity: "warn",
      detail: "Please select all column",
      life: 3000,
    });
  };

  const childRoomDRopDownHandle = (e, order_no) => {
    let exist_col_value = structuredClone(childRoom);
    exist_col_value[order_no] = { val: e.value };

    let col_value = [...columnData];

    console.log(exist_col_value, "exist_col_value");

    col_value.forEach((item) => {
      if (item.order === order_no) {
        const lable = roomTypedropdownOptions.filter(function (el) {
          return el.value === e.value;
        });
        item.docEntityValue = lable[0].label;
        item.entityColumnName = lable[0].value;
        setUpdateColumnNo(updateColumnNo + 1);
      }
    });
    setColumnData(col_value);
    setChildRoom(exist_col_value);
  };

  useEffect(() => {
    let exist_col_value = [...columnData];

    console.log(exist_col_value, "exist_col_value");

    // exist_col_value.forEach(item => {

    //   if(item.order === order_no){
    //     const lable = dropdownOptions.filter(function(el) {
    //       return el.value === evt.value
    //     })
    //     item.docEntityValue = lable[0].label
    //     item.entityColumnName = lable[0].value
    //     setUpdateColumnNo(updateColumnNo + 1)
    //   }
    // })
    // setColumnData(exist_col_value);
  }, [childRoom]);

  useEffect(() => {
    let upadateColumn = [];
    for (let i = 0; i < props.columnData.length; i++) {
      upadateColumn.push({
        docEntityValue: props.columnData[i].columnName,
        entityColumnName: props.columnData[i].columnName,
        order: props.columnData[i].order_no,
        userAdded: false,
      });
    }
    setColumnData(upadateColumn);

    let options = [];
    for (let i = 0; i < colData.length; i++) {
      if (colData[i].tariffColumnName === "hospital_room_type") {
        let room_type_options = [];
        for (let j = 0; j < colData[i].columnValues.length; j++) {
          room_type_options.push({
            label: colData[i].columnValues[j].title,
            value: colData[i].columnValues[j].tariffColumnValueName,
          });
        }
        setRoomTypedropdownOptions(room_type_options);
      }
      options.push({
        label: colData[i].title,
        value: colData[i].tariffColumnName,
      });
    }
    setdropdownOptions(options);
  }, []);

  useEffect(() => {
    console.log(roomTypedropdownOptions, "99999");
  }, [roomTypedropdownOptions]);

  const replaceHandle = async () => {
    if (updateColumnNo < columnData.length) {
      showWarn();
    } else {
      // let tariffData = {
      //   ...(payorHospitalId && {payorHospitalId: payorHospitalId}),
      //   ...(ihxHospitalId && {ihxHospitalId: ihxHospitalId}),
      //   ...(payorName && {payorName: payorName}),
      //   ...(hospitalName && {hospitalName: hospitalName})
      // }

      await dispatch(updateTariffColumnData(columnData, props.tariffID, {}));
      onHide();
    }
  };

  const onColumnChange = (evt, order_no) => {
    console.log(evt.value, "value", steps);

    setSteps((prevState) => ({
      ...prevState,
      [evt.target.name]: evt.value,
    }));

    let exist_col_value = [...columnData];

    console.log(exist_col_value, "exist_col_value");

    exist_col_value.forEach((item) => {
      if (item.order === order_no) {
        const lable = dropdownOptions.filter(function (el) {
          return el.value === evt.value;
        });
        item.docEntityValue = lable[0].label;
        item.entityColumnName = lable[0].value;
        setUpdateColumnNo(updateColumnNo + 1);
      }
    });
    setColumnData(exist_col_value);
  };

  const onHide = () => {
    setVisible(false);
  };

  const Header = () => {
    return (
      <div className="popup_header">
        {" "}
        <img src={Spinner} alt="spinner" className="spinner_loading" /> Your
        report is being generated...{" "}
      </div>
    );
  };

  return (
    <div>
      <Dialog
        visible={visible}
        onHide={onHide}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "50vw" }}
        header={Header}
        className="report_gen_popup"
      >
        <hr className="mt-0" />
        <div className="py-2 px-4">
          <h5 className="heading mt-0">
            You can select the category as well as the sub-category of the
            fields.
          </h5>
          {/* <div className="grid">
            <div className="col-4 pb-0">
              <InputText
                className="login_field py-1"
                value={payorHospitalId}
                onChange={(e) => setpayorHospitalId(e.target.value)}
                placeholder="Payor Hospital Id"
              />
            </div>
            <div className="col-4 pb-0">
              <InputText
                className="login_field py-1"
                value={payorName}
                onChange={(e) => setpayorName(e.target.value)}
                placeholder="Payor Name"
              />
            </div>
            <div className="col-4 pb-0">
              <InputText
                className="login_field py-1"
                value={hospitalName}
                onChange={(e) => setHospitalName(e.target.value)}
                placeholder="hospital Name"
              />
            </div>
            <div className="col-4 pb-0">
              <InputText
                className="login_field py-1"
                value={ihxHospitalId}
                onChange={(e) => setihxHospitalId(e.target.value)}
                placeholder="Ihx Hospital ID"
              />
            </div>
          </div> */}
          <table className="report-table mt-3">
            <thead>
              <tr>
                <th className="modal_texthead">In the document</th>
                <th className="modal_texthead">Type</th>
                <th className="modal_texthead">Std Field Value</th>
              </tr>
            </thead>
            <tbody>
              {props.columnData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <p className="modal_text">
                        {item.columnName}
                        <i
                          className="pi pi-pencil ml-2"
                          style={{ fontSize: ".9em" }}
                        ></i>
                      </p>
                    </td>
                    <td>
                      <i
                        className="pi pi-arrow-right mr-2"
                        style={{ fontSize: ".6em" }}
                      ></i>
                      <Dropdown
                        className="modal_dropdown"
                        value={
                          steps[item.columnName] ? steps[item.columnName] : ""
                        }
                        options={dropdownOptions}
                        placeholder="Select Column"
                        onChange={(e) => onColumnChange(e, item.order_no)}
                        name={item.columnName}
                      />
                    </td>
                    <td>
                      <Dropdown
                        className="modal_dropdown"
                        value={
                          steps[item.columnName] &&
                          steps[item.columnName] !== "hospital_room_type"
                            ? steps[item.columnName]
                            : childRoom[item.order_no]
                            ? childRoom[item.order_no].val
                            : ""
                        }
                        options={
                          steps[item.columnName] === "hospital_room_type"
                            ? roomTypedropdownOptions
                            : dropdownOptions
                        }
                        placeholder="Select Column"
                        onChange={
                          steps[item.columnName] === "hospital_room_type"
                            ? (e) => childRoomDRopDownHandle(e, item.order_no)
                            : onColumnChange
                        }
                        name={item.columnName}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="btn_div">
            <Button className="cancel_bth" label="Cancel" onClick={onHide} />

            <Button
              className="replace_btn"
              label="Replace"
              onClick={replaceHandle}
            />
          </div>
        </div>
      </Dialog>
      <Toast ref={toast} />
    </div>
  );
};

export default ReportGeneratedPopup;
