import React from "react";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import LoginImg from "../assets/login_img.png";
import LogoImg from '../assets/ihx-logo.jpg'

const LoginForm = (props) => {
  return (
    <div className="login-section">
      <div className="inner-login">
        <div className="grid align-items-center">
          <div className="col-6">
            <div className="logo_div">
              <img src={LogoImg} alt="logo"  className="logo_img"/>
              <h4 className="login_head">Welcome to IHX Tariff Studio</h4>
            </div>
            <div className="inner_wrapper">
              <InputText
                className="login_field"
                value={props.email}
                onChange={(e) => props.setEmail(e.target.value)}
                placeholder="Enter Username"
              />
              <Password
                style={{ width: "100%" }}
                inputStyle={{
                  width: "100%",
                  background: "rgb(255, 255, 255)",
                  border: "1px solid rgb(176, 176, 176)",
                  borderRadius: 7,
                }}
                value={props.password}
                onChange={(e) => props.setPassword(e.target.value)}
                feedback={false}
                placeholder="Enter Password"
              />
              <Button
                label="Login"
                className="login_btn"
                onClick={props.clickHandle}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="login_img_wrapper">
              <img src={LoginImg} className="login_img" alt="login_img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
