import axios from "axios";
import { authUrl } from "../../config/config";
import { get_config } from "../../utils";
import { login, loginFail, logout } from "../slices/authSlice";
import { startLoading, stopLoading } from "../slices/loaderSlice";

export const loginAction = (email, password) => async (dispatch) => {
  try {
    dispatch(startLoading())
    const data = {
      email: email,
      password: password,
    };
    const res = await axios.post(authUrl.login, data, get_config());
    dispatch(stopLoading())
    dispatch(login(res.data));
  } catch (err) {
    dispatch(stopLoading())
    dispatch(loginFail(err));
  }
};

export const logoutAction = (refreshToken) => async (dispatch) =>{
  try{
    const data = {
      'refreshToken': refreshToken,
    };
    const res = await axios.post(authUrl.logout, data, get_config())
    dispatch(logout(res.data))
  }catch(err){

  }
}