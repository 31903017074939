import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    authData:{},
    isLogin:false,
    mesg: null
    
}

const authSlice = createSlice({
    name:"auth",
    initialState,
    reducers:{
        login: (state, action) =>{
            state.authData = action.payload.data
            state.isLogin = true
            localStorage.setItem('access_token', action.payload.data.access.token)
            localStorage.setItem('refresh_token', action.payload.data.refresh.token)
        },
        loginFail: (state, action) =>{
            state.isLogin = false
            if(action.payload.response.status === 401){
                state.mesg = action.payload.response.data.message
            }else{
                state.mesg = action.payload.response.data.error
            }
            state.authData = {}
        },
        logout: (state, action) =>{
            localStorage.clear();
        }
    }
})

export const { login, loginFail, logout } = authSlice.actions
export default authSlice.reducer