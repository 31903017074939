export let baseApiUrl = process.env.REACT_APP_TARIFF_API_URL

export const authUrl = {
    login: baseApiUrl + 'v1/auth/login',
    register: baseApiUrl + 'v1/auth/register',
    logout: baseApiUrl + 'v1/auth/logout'
}

export const TariffUrl = {
    getAllTariff: baseApiUrl + 'v1/users/getTariffData',
    fileUpload: baseApiUrl + 'v1/users/uploadFile',
    tariffDEtail: baseApiUrl + 'v1/users/getTariffById',
    updateColumnData: baseApiUrl + 'v1/users/updateColumnDetails',
    updateTariffTable: baseApiUrl + 'v1/users/updateTableDetails/',
    updateTariffStatus: baseApiUrl + 'v1/users/tariffStatusChange',
    downloadTariff: baseApiUrl + 'v1/users//downloadTariffData/',
    tariffFilter: baseApiUrl + 'v1/users/filterTariffData',
    addNewColumn: baseApiUrl + 'v1/users/addColumnToTariff'
}