import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    status: null
}

const tariffDetailsrSlice = createSlice({
    name:"tariffDetails",
    initialState,
    reducers:{
        setTraiffstatus: (state, action) =>{
            state.status = action.payload.currentStatus
        },
    }
})

export const { setTraiffstatus } = tariffDetailsrSlice.actions
export default tariffDetailsrSlice.reducer