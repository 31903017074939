import React, { useEffect, useState } from "react";
import upload from "../assets/upload.png";
import "./component.scss";
import leftIco from "../assets/left-vector.png";
import rightIco from "../assets/right-vector.png";
import { Stage, Layer, Image } from "react-konva";
import { useDispatch, useSelector } from "react-redux";

const  FabricImages = (props) => {
  const [image, setImage] = useState(new window.Image());

  const [base64Image, setBase64Image] = useState(null)

  const tariffData = useSelector((state) => state.tariff.tariffFile);

  useEffect(() => {
    const img = new window.Image();
    img.src =
      "https://www.verywellhealth.com/thmb/co9NtREIgiYYI1RnQ5ZJi34X4O4=/614x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/mydoctorbill-56a6f6b23df78cf772912599.jpg";
    setImage(img);
  }, []);

  useEffect(() =>{
    if (tariffData){
      setBase64Image(tariffData)
    }
  },[tariffData])


  return (
    <div className="fabricSetion">
      <div className="fabTop">
        {/* <div className="fabHeader">
          <div>
            <img
              src={upload}
              className="uploPic mx-1"
              alt="upload your files"
            />
          </div>
          <p>
            Upload or drag and drop Documents
            <span className="lowFile">(File Format needs to be .pdf, .png, .jpg or jpeg).</span>
          </p>
        </div> */}
        <div className="pageCurrent">
          <div className="px-2 arrow_imgbx" onClick={() => props.changePageNoHandle('prev')} >
            <img src={leftIco} alt="Left page nav" />
          </div>
          <div>Pg {props.pageNo}/5</div>
          <div className="px-2 arrow_imgbx" onClick={() => props.changePageNoHandle('next')}>
            <img src={rightIco} alt="Right page nav" />
          </div>
        </div>
      </div>
      <div className="konvaImages">
        {tariffData && (
          <iframe className="embd_pdf" src={base64Image} />
        )}
      
        {/* <Stage width={796} height={890}>
          <Layer>
            <Image x={0} y={20} image={image} />

          </Layer>
        </Stage> */}
      </div>
    </div>
  );
}

export default FabricImages;
