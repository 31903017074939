import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import "./component.scss";
import RefreshImage from "../assets/referesh_icon.png";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getAllTariffAction } from "../redux/action/tariffAction";
import { setTraiffstatus } from "../redux/slices/tariffDetails";

import { tariffFilterAction } from "../redux/action/tariffAction";
import { convertTwoDigitMonth } from "../utils";

const Datatable = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [dateFilter, setDateFilter] = useState([]);
  const [dateFilter1, setDateFilter1] = useState({});
  const [filterstatus, seStatus] = useState("");
  const [search, setSearch] = useState("");
  const [filterDone, setFilterDone] = useState(false);

  useEffect(() => {
    if (dateFilter[1] !== null && dateFilter.length > 0) {
      let obj = dateFilter1;
      let from_date = new Date(dateFilter[0]);
      from_date = `${from_date.getFullYear()}-${convertTwoDigitMonth(
        from_date.getMonth() + 1
      )}-${convertTwoDigitMonth(from_date.getDate())}`;

      let to_date = new Date(dateFilter[1]);
      to_date = `${to_date.getFullYear()}-${convertTwoDigitMonth(
        to_date.getMonth() + 1
      )}-${convertTwoDigitMonth(to_date.getDate())}`;

      obj["from"] = from_date;
      obj["to"] = to_date;
      setDateFilter1(obj);
      setFilterDone(true);
      dispatch(tariffFilterAction(search, filterstatus, dateFilter1));
    }
  }, [dateFilter]);

  useEffect(() => {
    if (filterstatus !== "") {
      setFilterDone(true);
      dispatch(tariffFilterAction(search, filterstatus, dateFilter1));
    }
  }, [filterstatus]);

  const onSearchHandle = () => {
    setFilterDone(true);
    dispatch(tariffFilterAction(search, filterstatus, dateFilter1));
  };

  const rowClickTable = (e) => {
    dispatch(setTraiffstatus(e.data));
    navigate("/tariff-studio/" + e.data._id);
    props.setCurrentPage("tariff-studio");
  };

  const refreshClickHandle = () => {
    dispatch(getAllTariffAction());
  };

  const onStatusChange = (e) => {
    seStatus(e.value);
  };

  const onRemoveFilter = () => {
    setFilterDone(false);
    dispatch(getAllTariffAction());
    setSearch("")
    seStatus("")
    setDateFilter([])
  };

  const status = [
    { name: "Recived", code: "received" },
    { name: "Verified", code: "verified" },
  ];

  const header = (
    <div className="datatable_header">
      <div className="flex flex-row">
        <div className="flex">
          <div className="p-inputgroup">
            <InputText
              className="search_input"
              placeholder="Hospital Name/ID or Payor name/ID"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  onSearchHandle();
                }
              }}
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
            <Button
              icon="pi pi-search"
              className="p-button search_btn"
              onClick={onSearchHandle}
            />
          </div>
        </div>
        <div className="flex">
          <label className="filter_label">Filter by : </label>
          <Dropdown
            className="filter_selection"
            value={filterstatus}
            options={status}
            onChange={onStatusChange}
            optionLabel="name"
            placeholder="Select Status"
          />
        </div>
        <div className="flex">
          <Calendar
            className="datatable_calendars"
            model="claimList_Date"
            placeholder="dd/mm/yy"
            dateFormat="dd/mm/yy"
            showIcon="true"
            selectionMode="range"
            value={dateFilter}
            onChange={(e) => setDateFilter(e.value)}
          />
        </div>
        <div className="flex">
          {filterDone && (
            <i
              className="pi pi-filter-slash remove_filter"
              onClick={onRemoveFilter}
            ></i>
          )}

          <Button className="refresh_button" onClick={refreshClickHandle}>
            <img src={RefreshImage} alt={"refresh_icon"} />
          </Button>
        </div>
      </div>
    </div>
  );

  const statusTemplate = (data) => {
    return (
      <div
        className={`status-${
          data.currentStatus ? data.currentStatus.toLowerCase() : ""
        } truncated-long-text`}
      >
        {data.currentStatus}
      </div>
    );
  };

  return (
    <div className="datatable_section">
      <DataTable
        value={props.tariffList}
        header={header}
        responsiveLayout="scroll"
        className="tarrif_home_table"
        paginator
        rowHover
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        rows={10}
        rowsPerPageOptions={[10, 20, 50]}
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        onRowClick={(e) => rowClickTable(e)}
      >
        <Column field="hospitalName" header="Hospital Name"></Column>

        <Column field="ihxHospitalId" header="Ihx Hospital ID"></Column>
        <Column field="payorHospitalId" header="Payor Hospital ID"></Column>
        <Column field="payorName" header="Payor"></Column>
        <Column field="createdAt" header="Date OF Creation"></Column>
        <Column field="qc_user" header="QC User"></Column>
        <Column
          field="currentStatus"
          header="Status"
          body={statusTemplate}
        ></Column>
        <Column field="reviewer" header="Reviewer"></Column>
        <Column field="contractStartDate" header="Contract Start Date"></Column>
      </DataTable>
    </div>
  );
};

export default Datatable;
