import jwt_decode from 'jwt-decode'


export const get_config = () => {
  let config = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": [
        "GET",
        "POST",
        "PATCH",
        "PUT",
        "DELETE",
        "OPTIONS",
      ],
      "Authorization": 'Bearer ' + localStorage.getItem("access_token"),
    },
  };
  return config;
};

export const isLoggedIn = () =>{
  const token = localStorage.getItem('access_token')
  if(token) return true
  else return false
}

export const isTokenExpired = async (token) => {
  var decoded = jwt_decode(token)

  if (decoded.exp < Date.now() / 1000) {
    return true
  } else {
    return false
  }
}

export const convertText = (text) =>{
  let a = text.toLowerCase()
  var b = a.replace(/ /g,"_");
  return b
}

export function changeDatatableHeaderText(str) {
  let stri = str.split("_")
  stri = stri.join(" ")
  return stri.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

export const downloadBase64File = (contentBase64, fileName) =>{
  const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${contentBase64}`;
  const downloadLink = document.createElement('a');
  document.body.appendChild(downloadLink);

  downloadLink.href = linkSource;
  downloadLink.target = '_self';
  downloadLink.download = fileName;
  downloadLink.click(); 
}

export const convertTwoDigitMonth = (val) =>{
  if(val < 10){
      return '0'+val
  }else{
      return val
  }
}

export const checkTariffFinished = (status) =>{
  try{
    if(status.tariffStatus.currentStatus === "verified"){
      return true
    }else{
      return false
    }
  }catch(err){
    return false
  }
}