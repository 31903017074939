import axios from "axios";
import { TariffUrl } from "../../config/config";
import { get_config } from "../../utils";
import {
  getTariffList,
  uplaodFile,
  errorFileUpload,
  getTariffDeatils,
  errorTariffDetails,
  updateColumnDetails,
  updateTariffTableData,
  clearTariffStateValue,
  tariffFilter,
  addNewTariffColumn,
  tariffStatusUpdate
} from "../slices/tariffSlice";

import { startLoading, stopLoading } from "../slices/loaderSlice";

import { downloadBase64File } from "../../utils";

export const getAllTariffAction = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    const res = await axios.get(TariffUrl.getAllTariff, get_config());
    dispatch(getTariffList(res.data));
    dispatch(stopLoading());
  } catch (err) {
    // dispatch(loginFail(err));
    console.log(err);
  }
};

export const uploadFile = (data) => async (dispatch) => {
  try {
    dispatch(startLoading());
    dispatch(clearTariffStateValue());
    const res = await axios.post(TariffUrl.fileUpload, data, get_config());
    dispatch(uplaodFile(res.data));
    dispatch(stopLoading());
  } catch (err) {
    dispatch(errorFileUpload(err.data));
  }
};

export const tariffDetailsAction = (id) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const url = TariffUrl.tariffDEtail + "/" + id;
    const res = await axios.get(url, get_config());
    dispatch(getTariffDeatils(res.data));
    dispatch(stopLoading());
  } catch (err) {
    dispatch(errorTariffDetails(err));
  }
};

export const updateTariffColumnData =
  (data, id, tariffData) => async (dispatch) => {
    try {
      dispatch(startLoading());
      const req_data = {
        tariffId: id,
        tariffData: tariffData,
        columnValues: data,
      };
      const res = await axios.post(
        TariffUrl.updateColumnData,
        req_data,
        get_config()
      );
      dispatch(updateColumnDetails(res.data));
      dispatch(stopLoading());
    } catch (err) {}
  };

export const updateTariffTableAction = (data, id) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const req_data = {
      updateDetails: data,
    };
    let url = TariffUrl.updateTariffTable + id;
    const res = await axios.post(url, req_data, get_config());
    dispatch(updateTariffTableData(res.data));
    dispatch(stopLoading());
  } catch (err) {}
};

export const updateTariffStatus = (id, status) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const req_data = {
      tariffId: id,
      status: status,
    };
    const res = await axios.post(
      TariffUrl.updateTariffStatus,
      req_data,
      get_config()
    );
    dispatch(tariffStatusUpdate(status.toLowerCase()))
    dispatch(stopLoading());
  } catch (err) {
    dispatch(stopLoading());
  }
};

export const downloadTariffData = (id) => async (dispatch) => {
  try {
    const url = TariffUrl.downloadTariff + id;
    const res = await axios.get(url, get_config());
    downloadBase64File(res.data.excelData, res.data.tariffId);
  } catch (err) {}
};

export const tariffFilterAction =
  (search_value, status = "", date_range = {}) =>
  async (dispatch) => {
    try {
      let data = {
        searchType: [
          "payorHospitalId",
          "hospitalName",
          "payorHospitalId",
          "payorName",
        ],
        searchValue: search_value,
        dateRange: date_range,
        ...(status.code && { status: status.code }),
        page: 1,
      };
      const res = await axios.post(TariffUrl.tariffFilter, data, get_config());
      dispatch(tariffFilter(res.data));
    } catch (err) {}
  };

export const addNewTariffColumnAction = (colData, tariffId) => async (dispatch) => {
  try{
    dispatch(startLoading());
    let data = {
      tariffId: tariffId,
      columnValues: [
        {
          docEntityValue: colData['name'],
          entityColumnName: colData['code'],
          userAdded: true,
        },
      ],
    };
  
    const res = await axios.post(TariffUrl.addNewColumn, data, get_config())
    dispatch(stopLoading());
    dispatch(addNewTariffColumn(colData))
  }catch(err){
    dispatch(stopLoading());
    dispatch(addNewTariffColumn(colData))
  }
};
